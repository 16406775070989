import axios from "axios";
import { AKV } from "../Constants/appKeys";

axios.defaults.baseURL = AKV.REACT_APP_API_URL;

export const getTemporaryToken = (staticToken) => {
  var postData = {
    staticToken: staticToken,
  };

  return axios.post("/api/v1/authentication", postData, {
    headers: {
      "content-type": "application/json,charset=UTF-8",
    },
  });
};

export const getMerchantInfo = (merchantEmail, tempToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;
  return axios.get(`/api/v1/merchant/details?email=${merchantEmail}`);
};

export const getProductsList = (staticToken) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    return axios.get(`/api/v1/product/`);
  });
};

export const getCategory = (staticToken) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    return axios.get(`api/v1/category`);
  });
};

export const updateCategory = (staticToken, id, name, position) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;

    const params = {
      name,
      position,
    };

    axios.defaults.headers.put["categoryId"] = id;
    return axios.put(`api/v1/category/`, params);
  });
};

export const createCategory = (staticToken, name, position) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;

    const params = {
      name,
      position,
    };

    return axios.post(`api/v1/category/`, params);
  });
};

export const deleteCategory = (staticToken, id) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    return axios.delete(`api/v1/category/${id}`);
  });
};

export const getGroupsByProductId = (staticToken, productId) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;

    axios.defaults.headers["productId"] = productId;
    return axios.get(`/api/v1/group/`);
  });
};

export const createProduct = (
  staticToken,
  name,
  description,
  imageUrl,
  value,
  isActive,
  peopleToServe,
  categories,
  salePrice
) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    const params = {
      name,
      description,
      imageUrl,
      value,
      isActive,
      peopleToServe,
      categories,
      salePrice,
    };
    return axios.post(`api/v1/product`, params);
  });
};

export const updateProduct = (
  staticToken,
  id,
  name,
  description,
  imageUrl,
  value,
  isActive,
  peopleToServe,
  categories,
  salePrice
) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    const params = {
      name,
      description,
      imageUrl,
      value,
      isActive,
      peopleToServe,
      categories,
      salePrice,
    };
    axios.defaults.headers.put["productId"] = id;
    return axios.put(`api/v1/product`, params);
  });
};

export const deleteProduct = (staticToken, id) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    return axios.delete(`api/v1/product/${id}`);
  });
};

export const getGroupsProduct = (staticToken, idProduct) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;
    axios.defaults.headers.get["productId"] = idProduct;
    return axios.get(`api/v1/group`);
  });
};

export const deleteGroup = (staticToken, id) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;

    axios.defaults.headers["groupId"] = id;
    return axios.delete(`api/v1/group/`);
  });
};

export const updateGroup = (
  staticToken,
  groupId,
  title,
  productId,
  maxQuantity,
  minQuantity,
  position,
  groupItemDto
) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;

    const params = {
      title,
      productId,
      maxQuantity,
      minQuantity,
      position,
      groupItemDto,
    };

    axios.defaults.headers["groupId"] = groupId;
    return axios.put(`api/v1/group/`, params);
  });
};

export const createGroup = (
  staticToken,
  title,
  type,
  productId,
  maxQuantity,
  minQuantity,
  position,
  groupItemDto
) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.temporaryToken}`;

    const params = {
      title,
      productId,
      maxQuantity,
      minQuantity,
      position,
      groupItemDto,
    };

    axios.defaults.headers["GroupType"] = type;
    return axios.post(`api/v1/group/`, params);
  });
};
