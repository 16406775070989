export const convertToReal = (priceNumber) => {
  if (priceNumber) {
    return priceNumber.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  } else {
    return "R$ 0,00";
  }
};

export const addLeadingZeros = (str, targetLength) => {
  return str.padStart(targetLength, "0");
};

export const getFormattedDate = (fullDate) => {
  const date = new Date(fullDate);
  return date.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const validatePrice = (price) => {
  return /^\s*(?:[0-9]\d{0,2}(?:\.\d{3})*|0)(?:,\d{1,2})?$/.test(price);
};

export const greaterThan = (firstValue, secondValue, checkEquals) => {
  firstValue = formatPriceToNumber(firstValue);
  secondValue = formatPriceToNumber(secondValue);
  return checkEquals
    ? Number(firstValue) >= Number(secondValue)
    : Number(firstValue) > Number(secondValue);
};

export const formatPriceToNumber = (price) => {
  return Number(price.replace(".", "").replace(",", ".")).toFixed(2);
};

export const getUrlParams = (appData) => {
  return `appData=${appData}`;
};

export const getGroupType = (groupType) => {
  if (groupType === "OneChoice") return 1;
  if (groupType === "MultipleChoice") return 2;
  if (groupType === "Extras") return 3;
};
