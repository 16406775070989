import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Divider } from "../../Divider";
import "./style.scss";

export const GroupList = ({ groups, radioClick }) => {
  const handleRowClick = (index) => {
    groups[index].ref.current.click();
  };

  const getGroupType = (group) => {
    let option = group.groupItemViewDto.length < 1 ? " opção" : " opções";

    if (group.groupType === "OneChoice") {
      return "Escolha Única - " + group.groupItemViewDto.length + option;
    }
    if (group.groupType === "MultipleChoice") {
      return "Escolha Multipla - " + group.groupItemViewDto.length + option;
    }
    if (group.groupType === "Extras") {
      return "\nExtras - " + group.groupItemViewDto.length + option;
    }
  };

  return (
    <List>
      <FormControl sx={{ width: "100% !important" }}>
        <RadioGroup
          name="radio-buttons-group"
          style={{ width: "100% !important" }}
          onChange={(e) => radioClick(e.target.value)}
        >
          {groups.map((group, index) => {
            const labelId = `checkbox-list-label-${group.id}`;
            if (group?.groupType !== "Observation") {
              return (
                <ButtonBase component="div" key={index} className="list-item">
                  <ListItem
                    disablePadding
                    onClick={() => handleRowClick(index)}
                  >
                    <FormControlLabel
                      value={group?.id}
                      control={<Radio ref={group?.ref} />}
                    />
                    <ListItemText
                      id={labelId}
                      primary={group?.title}
                      secondary={getGroupType(group)}
                    />
                  </ListItem>
                  <Divider length={groups.length} index={index} />
                </ButtonBase>
              );
            }
          })}
        </RadioGroup>
      </FormControl>
    </List>
  );
};
