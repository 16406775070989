export const successfull = {
  save: {
    content: "Produto salvo com sucesso!",
    variant: "success",
  },
  updateSave: {
    content: "Produto atualizado com sucesso!",
    variant: "success",
  },
  deleteProduct: {
    content: "Produto removido com sucesso!",
    variant: "success",
  },
  deleteGroup: {
    content: "Grupo removido com sucesso!",
    variant: "success",
  },
  deleteGroupItem: {
    content: "Opção removida com sucesso!",
    variant: "success",
  },
  updateGroup: {
    content: "Grupo de complementos atualizado com sucesso!",
    variant: "success",
  },
  createGroup: {
    content: "Grupo de complementos criado com sucesso!",
    variant: "success",
  },
  deleteCategory: {
    content: "Categoria de Produto deletada com sucesso!",
    variant: "success",
  },
  updateCategory: {
    content: "Categoria de Produtos atualizado com sucesso!",
    variant: "success",
  },
  createCategory: {
    content: "Categoria de Produtos criada com sucesso!",
    variant: "success",
    icon: "like",
  },
};

export const failed = {
  save: {
    content: "Erro ao salvar produto.",
    variant: "error",
  },
  updateSave: {
    content: "Ocorreu algum erro ao tentar atualizar seu produto.",
    variant: "error",
  },
  deleteProduct: {
    content: "Ocorreu algum erro ao tentar remover seu produto.",
    variant: "error",
  },
  deleteGroup: {
    content: "Ocorreu algum erro ao tentar remover seu grupo.",
    variant: "error",
  },
  updateGroup: {
    content: "Ocorreu um erro ao atualizar o grupo",
    variant: "error",
  },
  createGroup: {
    content: "Ocorreu um erro ao criar o grupo",
    variant: "error",
  },
  updateGroupOneChoice: {
    content: "Você deve ter pelo menos 2 opções cadastradas",
    variant: "error",
  },
  updateGroupMultipleChoiceOrExtras: {
    content: "Você deve ter pelo menos 1 opção cadastrada",
    variant: "error",
  },

  createGroupWithMinimumOfGroupItems: {
    content:
      "O número de opções inseridas é menor que a quantidade mínima de escolhas",
    variant: "error",
  },

  createGroupWithMinimumGroupExtras: {
    content: "Quantidade mínima de um grupo extras deve ser 0",
    variant: "error",
  },
  associationDeleteCategory: {
    content: "Esta categoria possui um ou mais itens associados.",
    variant: "error",
  },
  deleteCategory: {
    content: "Ocorreu uma falha ao tentar remover a categoria.",
    variant: "error",
  },
  updateCategory: {
    content: "Ocorreu uma falha ao tentar atualizar a categoria.",
    variant: "error",
  },
  createCategory: {
    content: "Ocorreu uma falha ao tentar criar a categoria.",
    variant: "error",
  },
};
