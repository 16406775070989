import React, { useContext, useEffect, useState } from "react";
import {
  createProduct,
  deleteGroup,
  deleteProduct,
  getCategory,
  getGroupsProduct,
  updateProduct,
} from "../../services/BlipFoods";
import {
  addLeadingZeros,
  convertToReal,
  getFormattedDate,
  greaterThan,
  validatePrice,
  getUrlParams,
} from "../../services/common";
import { AlertModal } from "../AlertModal";
import { ImageUploader } from "../ImageUploader";
import "./style.scss";
import UserContext from "../../Context/User";
import { successfull, failed } from "../../Constants/toast";
import { TRACKING } from "../../services/Tracking";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../../services/ZustandConfig";
import { Button, Paper, Switch, Tooltip } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { CategoryContainer } from "./CategoryContainer";
import { GroupContainer } from "./GroupContainer";
import { TextArea } from "../TextArea";
import { AnalyticsService as _analyticsService } from "../../services/analyticsService/AnalyticsService";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const ProductDetail = ({ product }) => {
  const [user] = useContext(UserContext);
  const [title, setTitle] = useState();
  const [basicInfo, setBasicInfo] = useState();
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState();
  const [price, setPrice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [portions, setPortions] = useState();
  const [description, setDescription] = useState("");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openDeleteGroupAlert, setOpenDeleteGroupAlert] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [categoryError, setCategoryError] = useState("");
  const [groupsProduct, setGroupsProduct] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let [
    allProducts,
    selectedProduct,
    populateAllProducts,
    updateSelectedProduct,
    removeProductByIndex,
    removeSelectedProduct,
    updateProductByIndex,
    updateGroupZS,
    updateNotification,
    setOpenDrawer,
  ] = useStore((state) => [
    state.allProducts,
    state.selectedProduct,
    state.populateProducts,
    state.updateSelectedProduct,
    state.removeProductByIndex,
    state.removeSelectedProduct,
    state.updateProductByIndex,
    state.updateGroupZS,
    state.updateNotification,
    state.setOpenDrawer,
  ]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setTitle({ value: product?.name, error: "" });
    setIsActive(product?.isActive);
    setImage({ value: product?.imageUrl, error: "" });
    setDescription({ value: product?.description });
    setPortions({ value: product?.peopleToServe, error: "" });
    setAllCategories([]);
    setGroupsProduct([]);
    setCategoryError("");
    getCategory(user.token).then((res) => {
      const categoryIds = product?.categories?.map((item) => item.id);

      // insere a propriedade de selected nas categorias selecionadas do produto
      const categories = res.data.map((category) => {
        category.selected = categoryIds?.includes(category.id);
        return category;
      });

      setAllCategories(categories); // salva todas as categorias com a propriedade de selected para ser exibido no front
    });

    if (product && product.id) {
      getGroupsProduct(user.token, product?.id).then((res) => {
        setGroupsProduct(res.data);
      });
    }

    setBasicInfo({ id: product?.id, createdAt: product?.createdAt });

    const price = convertToReal(product?.value).substring(3);
    const salePrice = convertToReal(product?.salePrice).substring(3);
    setPrice({ value: price, error: "" });
    setSalePrice({ value: salePrice, error: "" });
  }, [product]);

  const saveProductTrack = (
    id,
    productState,
    saveState,
    categories,
    errors
  ) => {
    _analyticsService.Track(TRACKING.saveProduct, {
      merchantName: user.name,
      idProduct: id,
      productName: title.value,
      source: productState,
      publishProduct: isActive,
      numberCategories: categories?.length,
      status: saveState,
      error: errors,
    });
  };

  const deleteProductClickButtonTrack = (id) => {
    _analyticsService.Track(TRACKING.deleteProductClicButton, {
      merchantName: user.name,
      idProduct: id,
      productName: title.value,
    });
  };

  const deleteProductConfirmedTrack = (id, saveState, errors) => {
    _analyticsService.Track(TRACKING.deleteProductConfirmed, {
      merchantName: user.name,
      idProduct: id,
      productName: title.value,
      status: saveState,
      error: errors,
    });
  };

  const cancelProductEditionTrack = () => {
    _analyticsService.Track(TRACKING.cancelProductEdition, {
      merchantName: user.name,
    });
  };

  const removeProduct = () => {
    setOpenDeleteAlert(false);
    const indexProduct = allProducts.findIndex((p) => p.id === basicInfo.id);
    deleteProduct(user.token, basicInfo.id)
      .then(() => {
        updateNotification(
          true,
          successfull.deleteProduct.content,
          successfull.deleteProduct.variant
        );
        deleteProductConfirmedTrack(basicInfo.id, "success");
        removeProductByIndex(indexProduct);
        removeSelectedProduct();
        setOpenDrawer(false);
      })
      .catch((e) => {
        deleteProductConfirmedTrack(
          basicInfo.id,
          "failed",
          e.response.data.errors
        );
        updateNotification(
          true,
          failed.deleteProduct.content,
          failed.deleteProduct.variant
        );
      });
  };

  const changeProduct = async (categories) => {
    let allSelectedCategories = allCategories?.filter(
      (item) => item.selected === true
    );

    salePrice.value = salePrice.value ? salePrice.value : "0,00";
    updateProduct(
      user.token,
      basicInfo.id,
      title.value,
      description.value,
      image.value,
      price.value.replace(".", "").replace(",", "."),
      isActive,
      portions.value,
      categories, // * passa o array com os ids das categorias que vão atualizar [2,31]
      salePrice.value.replace(".", "").replace(",", ".")
    )
      .then((res) => {
        updateNotification(
          true,
          successfull.updateSave.content,
          successfull.updateSave.variant
        );
        setOpenDrawer(false);
        saveProductTrack(res.data.id, "edicao", "success", categories);
        const indexProduct = allProducts.findIndex(
          (product) => product.id === res.data.id
        );
        updateProductByIndex(indexProduct, {
          isActive,
          id: res.data.id,
          createdAt: basicInfo.createdAt,
          description: description.value,
          categories: allSelectedCategories,
          imageUrl: image.value,
          name: title.value,
          peopleToServe: portions.value,
          salePrice: res.data.salePrice,
          value: res.data.value,
        });

        updateProduct({
          isSelected: true,
          isActive,
          id: res.data.id,
          createdAt: basicInfo.createdAt,
          description: description.value,
          categories: allSelectedCategories,
          imageUrl: image.value,
          name: title.value,
          peopleToServe: portions.value,
          salePrice: res.data.salePrice,
          value: res.data.value,
        });
      })
      .catch((e) => {
        saveProductTrack(
          "edicao",
          "failed",
          categories,
          e.response.data.errors
        );
        updateNotification(
          true,
          failed.updateSave.content,
          failed.updateSave.variant
        );
      });
  };

  const saveProduct = async () => {
    const priceError = handlePriceChange(price.value);
    const salePriceError = handleSalePriceChange(salePrice.value);
    const titleError = handleTitleChange(title.value);
    const portionsError = handlePortionChange(portions.value);
    const hasImage = !!image.value;
    setImage((current) => {
      return {
        ...current,
        error: hasImage ? "" : "Insira uma imagem do produto.",
      };
    });
    const hasCategory = allCategories.some(
      (category) => category.selected === true
    );

    setCategoryError(hasCategory ? "" : "Escolha pelo menos uma categoria.");
    if (
      priceError ||
      salePriceError ||
      titleError ||
      !hasCategory ||
      portionsError ||
      !hasImage
    ) {
    } else {
      const categories = allCategories
        .filter((item) => item.selected)
        .map((item) => item.id);

      // salva no contexto apenas as categorias selecionadas
      let allSelectedCategories = allCategories?.filter(
        (item) => item.selected === true
      );

      if (basicInfo.id === 0) {
        salePrice.value = salePrice.value ? salePrice.value : "0,00";
        createProduct(
          user.token,
          title.value,
          description.value,
          image.value,
          price.value.replace(".", "").replace(",", "."),
          isActive,
          portions.value,
          categories,
          salePrice.value.replace(".", "").replace(",", ".")
        )
          .then((res) => {
            updateNotification(
              true,
              successfull.save.content,
              successfull.save.variant
            );
            setBasicInfo((current) => {
              return {
                ...current,
                id: res.data.id,
              };
            });
            saveProductTrack(res.data.id, "novo", "success", categories);
            populateAllProducts([
              {
                isSelected: false,
                isActive,
                id: res.data.id,
                createdAt: basicInfo.createdAt,
                description: description.value,
                categories: allSelectedCategories,
                imageUrl: image.value,
                name: title.value,
                peopleToServe: portions.value,
                salePrice: res.data.salePrice,
                value: res.data.value,
              },
              ...allProducts,
            ]);

            syncSelectProductChanges(res.data.id);
          })
          .catch((e) => {
            saveProductTrack(
              0,
              "novo",
              "failed",
              categories,
              e.response.data.errors
            );
            updateNotification(true, failed.save.content, failed.save.variant);
          });
      } else {
        changeProduct(categories);
      }
    }
  };

  const openGroupEdition = (group) => {
    _analyticsService.Track(TRACKING.editGroup, {
      merchantName: user?.name,
      groupName: group?.title,
      idProduct: selectedProduct?.id,
      productName: selectedProduct?.name,
    });

    updateGroupZS(group);

    navigate(
      `groups/edit/${group.id}/?` + getUrlParams(searchParams.get("appData"))
    );
  };

  const openNewGroup = async () => {
    _analyticsService.Track(TRACKING.newGroupProductDetail, {
      merchantName: user.name,
      idProduct: basicInfo.id,
      productName: selectedProduct.name,
    });

    navigate(`groups/add/?` + getUrlParams(searchParams.get("appData")));
  };

  const openDeleteGroupModal = async (group) => {
    setSelectedGroup(group);
    setOpenDeleteGroupAlert(true);
  };

  const trackGroupDelete = (status) => {
    _analyticsService.Track(TRACKING.deleteGroupConfirmed, {
      merchantName: user.name,
      groupName: selectedGroup?.title,
      status,
    });
  };

  const handleDeleteGroup = () => {
    deleteGroup(user.token, selectedGroup.id)
      .then(() => {
        updateNotification(
          true,
          successfull.deleteGroup.content,
          successfull.deleteGroup.variant
        );
        trackGroupDelete("success");
      })
      .catch(() => {
        updateNotification(
          true,
          failed.deleteGroup.content,
          failed.deleteGroup.variant
        );
        trackGroupDelete("failed");
      })
      .finally(() => {
        setOpenDeleteGroupAlert(false);
        setGroupsProduct([]);
        getGroupsProduct(user.token, product?.id).then((res) => {
          setGroupsProduct(res.data);
        });
      });
  };

  const handleDeleteProduct = () => {
    deleteProductClickButtonTrack(basicInfo.id);
    setOpenDeleteAlert(true);
  };

  const handleSalePriceChange = (value) => {
    let errorMessage;

    var priceNumber = Number(price.value.replace(",", "."));

    if (priceNumber === 0) {
      setSalePrice({ value: 0, error: errorMessage });
      return !!errorMessage;
    } else if (!!value && greaterThan(value, price.value, true)) {
      errorMessage = "Escolha um valor menor que o preço comum.";
    } else if (!!value) {
      errorMessage = validatePrice(value) ? "" : "Insira um valor válido.";
    }
    setSalePrice({ value, error: errorMessage });
    return !!errorMessage;
  };

  const handlePriceChange = (value) => {
    let errorMessage;

    var priceNumber = Number(value.replace(",", "."));

    if (priceNumber === 0) {
      setSalePrice({ value: 0, error: errorMessage });
    } else if (value && greaterThan(value, "0", false)) {
      errorMessage = validatePrice(value) ? "" : "Insira um valor válido.";
    } else {
      errorMessage = "Preenchimento obrigatório";
    }

    setPrice({ value, error: errorMessage });
    return !!errorMessage;
  };

  const handlePortionChange = (value) => {
    let errorMessage;
    if (value < 0) {
      errorMessage = "Insira um valor maior ou igual a 0";
    } else if (value === "") {
      errorMessage = "Preenchimento obrigatório";
    }
    setPortions({ value, error: errorMessage });
    return !!errorMessage;
  };

  const handleDescriptionChange = (value) => {
    setDescription(() => {
      return { value };
    });
    return false;
  };

  const handleTitleChange = (value) => {
    const errorMessage = value ? "" : "Preenchimento obrigatório";
    setTitle({ value, error: errorMessage });
    return !!errorMessage;
  };

  const handleCategoryChange = (index) => {
    setAllCategories(() => {
      return [
        ...allCategories.slice(0, index),
        {
          ...allCategories[index],
          selected: !allCategories[index].selected,
        },
        ...allCategories.slice(index + 1),
      ];
    });
  };

  const hideDetailsPanel = () => {
    removeSelectedProduct();
    setOpenDrawer(false);
    cancelProductEditionTrack();
  };

  const BottomActions = () => {
    return (
      <div className="bottom-action-buttons">
        {basicInfo?.id === 0 ? (
          <Button
            variant="outlined"
            startIcon={<CloseRoundedIcon />}
            onClick={() => hideDetailsPanel()}
          >
            Cancelar
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={<DeleteOutlinedIcon />}
            onClick={() => handleDeleteProduct()}
            color="error"
          >
            Apagar
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<CheckOutlinedIcon />}
          onClick={() => saveProduct()}
        >
          Salvar
        </Button>
      </div>
    );
  };

  const togglePublish = (ev) => {
    setIsActive(ev.target.checked);

    _analyticsService.Track(TRACKING.publish, {
      productName: title.value,
      source: basicInfo.id === 0 ? "novo" : "edit",
      idProduct: basicInfo.id,
      merchantName: user.name,
      status: ev.target.checked,
    });
  };

  const syncSelectProductChanges = (id) => {
    let allSelectedCategories = allCategories?.filter(
      (item) => item.selected === true
    );
    updateSelectedProduct({
      ...selectedProduct,
      id,
      isActive,
      description: description.value,
      categories: allSelectedCategories,
      imageUrl: image.value,
      name: title.value,
      peopleToServe: portions.value,
      salePrice: parseFloat(salePrice.value.replace(".", "").replace(",", ".")),
      value: parseFloat(price.value.replace(".", "").replace(",", ".")),
    });
  };

  const openListCategories = async (mode) => {
    syncSelectProductChanges(basicInfo?.id);

    if (mode === "new") {
      _analyticsService.Track(TRACKING.newCategoryProductDetail, {
        merchantName: user.name,
      });
    } else {
      _analyticsService.Track(TRACKING.editCategoryProductDetail, {
        merchantName: user.name,
        idProduct: selectedProduct.id,
        productName: selectedProduct.name,
      });
    }

    navigate(
      `categories/?` +
        getUrlParams(searchParams.get("appData") + `&mode=` + mode)
    );
  };

  const GroupCard = () => {
    let showAddButton = false;
    if (basicInfo?.id !== 0) {
      showAddButton = true;
    }
    return (
      <Paper sx={{ padding: "16px" }} className="card-info">
        <div className="content-label-container">
          <div className="title-groups">
            <span className="title grey-text">Grupo de Complementos</span>
            {showAddButton ? (
              <Button
                variant="text"
                startIcon={<AddOutlinedIcon />}
                onClick={() => openNewGroup()}
              >
                Novo grupo
              </Button>
            ) : (
              <></>
            )}
          </div>
          <GroupContainer
            openDeleteGroupModal={(group) => openDeleteGroupModal(group)}
            openGroupEdition={(group) => openGroupEdition(group)}
            groups={groupsProduct}
            itemId={basicInfo?.id}
          />
        </div>
      </Paper>
    );
  };

  return (
    <div className="main-container-product-info" id="rightSideContainer">
      <AlertModal
        open={openDeleteAlert}
        title="Exclusão do produto"
        content="Atenção! Se você excluir seu produto, não poderá mais recuperá-lo. Você tem certeza disso?"
        variant="delete"
        handleAgreeEvent={() => removeProduct()}
        handleDisagreeEvent={() => setOpenDeleteAlert(false)}
      />
      <AlertModal
        open={openDeleteGroupAlert}
        title="Exclusão do Grupo de Complementos"
        content="Atenção! Se você excluir esse grupo, não poderá mais recuperá-lo. Você tem certeza disso?"
        variant="delete"
        handleAgreeEvent={() => handleDeleteGroup()}
        handleDisagreeEvent={() => setOpenDeleteGroupAlert(false)}
      />
      <div className="product-image-container">
        <ImageUploader
          image={image?.value}
          error={image?.error}
          newImageEvent={(img) =>
            setImage(() => {
              return { value: img, error: "" };
            })
          }
        />
      </div>
      <div className="form-grid-container">
        <div className="horizontal-container">
          <div className="content-label-container">
            <span className="title grey-text">Publicado:</span>
            <Switch
              checked={isActive}
              onChange={(e) => togglePublish(e)}
            ></Switch>
          </div>
          <div className="content-label-container">
            <span className="title grey-text">ID:</span>
            <span className="f6 lh-copy">
              {addLeadingZeros(String(basicInfo?.id), 6)}
            </span>
          </div>
          <div className="content-label-container">
            <span className="title grey-text">Criado em:</span>
            <span className="f6 lh-copy">
              {basicInfo?.createdAt
                ? getFormattedDate(basicInfo?.createdAt)
                : getFormattedDate("")}
            </span>
          </div>
        </div>
        <Paper className="form-grid-container card-info" sx={{ padding: "16px" }} >
          <div className="title-groups">
            <span className="title grey-text">Informações básicas</span>
          </div>
          <TextArea
            placeholder="Um nome que descreva bem o produto"
            label="Título"
            text={title?.value}
            maxLength={255}
            emitChange={(value) => handleTitleChange(value)}
            error={!!title?.error}
            helperText={title?.error}
          />

          <div className="price-grid-container">
            <TextArea
              label="Preço"
              text={price?.value}
              maxLength={255}
              emitChange={(value) => handlePriceChange(value)}
              error={!!price?.error}
              helperText={price?.error}
            />
            <TextArea
              label="Preço promocional"
              text={salePrice?.value}
              maxLength={255}
              emitChange={(value) => handleSalePriceChange(value)}
              error={!!salePrice?.error}
              helperText={salePrice?.error}
            />
            <TextArea
              label="Pessoas servidas"
              text={portions?.value}
              maxLength={255}
              emitChange={(value) => handlePortionChange(value)}
              error={!!portions?.error}
              helperText={portions?.error}
              type="number"
              min={0}
            />
          </div>
          <TextArea
            label="Descrição"
            text={description?.value}
            maxLength={1000}
            emitChange={(value) => handleDescriptionChange(value)}
            multiline={true}
          />
        </Paper>
        <Paper sx={{ padding: "16px" }} className="card-info">
          <div className="content-label-container">
            <div className="title-groups">
              <span className="title grey-text">Categoria</span>
              <div className="new-group-button">
                <Tooltip placement="left" title="Criar nova categoria">
                  <Button
                    variant="text"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => openListCategories("new")}
                  >
                    Nova categoria
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Editar categorias">
                  <Button
                    variant="text"
                    startIcon={<EditOutlinedIcon />}
                    onClick={() => openListCategories("edit")}
                  >
                    Editar
                  </Button>
                </Tooltip>
              </div>
            </div>
            <CategoryContainer
              categories={allCategories}
              error={categoryError}
              categoryChange={(index) => handleCategoryChange(index)}
            />
          </div>
        </Paper>
        <GroupCard />
        <BottomActions />
      </div>
    </div>
  );
};
