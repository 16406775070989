import { create } from "zustand";
import { emptyProduct } from "../Constants/product";

export const useStore = create((set) => ({
  // * Produtos (produto, categoria, grupo)
  allProducts: [],
  selectedProduct: {},
  categories: [],
  group: {},

  // * Manipulação de produtos e derivados
  updateProductByIndex: (index, value) => {
    set((state) => ({
      allProducts: [
        ...state.allProducts.slice(0, index),
        value,
        ...state.allProducts.slice(index + 1),
      ],
    }));
  },
  removeProductByIndex: (index) => {
    set((state) => ({
      allProducts: [
        ...state.allProducts.slice(0, index),
        ...state.allProducts.slice(index + 1),
      ],
    }));
  },
  removeSelectedProduct: () => {
    set(() => ({
      selectedProduct: emptyProduct,
    }));
  },
  populateProducts: (products) => {
    set(() => ({
      allProducts: products,
    }));
  },
  updateSelectedProduct: (product) => {
    set(() => ({
      selectedProduct: product,
    }));
  },
  updateGroupZS: (newGroup) => {
    set(() => ({
      group: newGroup,
    }));
  },
  removeGroupItemByIndex: (index) => {
    set((state) => ({
      group: {
        ...state.group,
        groupItemViewDto: [
          ...state.group.groupItemViewDto.slice(0, index),
          ...state.group.groupItemViewDto.slice(index + 1),
        ],
      },
    }));
  },
  editGroupItemByIndex: (index, groupItem) => {
    set((state) => ({
      group: {
        ...state.group,
        groupItemViewDto: [
          ...state.group.groupItemViewDto.slice(0, index),
          groupItem,
          ...state.group.groupItemViewDto.slice(index + 1),
        ],
      },
    }));
  },

  // * Notificação e sua manipulação
  showNotification: false,
  notificationContent: { show: false, message: "", variant: "success" },
  updateNotification: (show, message, variant) => {
    set(() => ({
      notificationContent: { show, message, variant },
    }));
  },

  // * Drawer
  openDrawer: false,
  setOpenDrawer: (newState) => {
    set(() => ({
      openDrawer: newState,
    }));
  },
}));
