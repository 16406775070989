import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "tachyons";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import { ContextWrapper } from "./Components/ContextWrapper";
import { Notification } from "./Components/Notification";
import { Categories } from "./Pages/Categories";
import { AddGroup } from "./Pages/Groups/AddGroup";
import { EditGroup } from "./Pages/Groups/Edit";
import { Home } from "./Pages/Home";
import { useStore } from "./services/ZustandConfig";

export default function App() {
  let [notificationContent, updateNotification] = useStore((state) => [
    state.notificationContent,
    state.updateNotification,
  ]);

  const closeNotification = (reason) => {
    updateNotification(false, "", "success");

    if (reason === "clickaway") {
      return;
    }
  };
  return (
    <ContextWrapper>
      <Notification
        showNotification={notificationContent?.show}
        variant={notificationContent?.variant}
        content={notificationContent?.message}
        key={notificationContent?.message}
        handleClose={(reason) => closeNotification(reason)}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/groups/edit/:groupId" element={<EditGroup />} />
          <Route path="/groups/add/" element={<AddGroup />} />
          <Route path="/categories/" element={<Categories />} />
          <Route path="/categories/add/" element={<AddGroup />} />
        </Routes>
      </BrowserRouter>
    </ContextWrapper>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

reportWebVitals();
