import { BlipTo } from "../constants/Commands/BlipTo";
import { BlipMethod } from "../constants/Commands/BlipMethod";
import { BlipType } from "../constants/Commands/BlipType";
import { v4 as uuidv4 } from "uuid";

export class BlipCommandBaseRequest {
  id = uuidv4();
  constructor(uri, method, to, type, from, pp) {
    this.uri = uri;
    this.method = BlipMethod[method];

    if (type !== undefined) this.type = BlipType[type];

    if (to !== undefined) this.to = BlipTo[to];

    if (from !== undefined) this.from = from;

    if (pp !== undefined) this.pp = pp;
  }
}
