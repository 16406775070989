import React, { useEffect } from "react";
import { _logMain } from "../../Configs/LogConfig";
import { AnalyticsService as _analyticsService } from "../../services/analyticsService/AnalyticsService";
import { LocalStorageService as _localStorageService } from "../../services/localStorageService/LocalStorageService";
import { QueryStringService as _queryStringService } from "../../services/queryStringService/QueryStringService";
import { GetBotKey } from "../../utils/Base64Formatted";
import { ProductsManager } from "../ProductsManager";

export const Home = () => {
  const _logger = _logMain.getChildCategory("App");
  const _userTraits = "userTraits";

  useEffect(() => {
    try {
      let appData = _queryStringService.TryGetAppData();

      _analyticsService
        .GetUserTraitsAsync(
          GetBotKey(appData.routerdata.shortName, appData.routerdata.accessKey),
          appData.profile?.name,
          appData.profile?.email,
          process.env.REACT_APP_MSGING_URL
        )
        .then((resp) => {
          _logger.info("Adding user traits to local storage");
          _localStorageService.Add(_userTraits, resp);
        });
    } catch (error) {
      _logger.error("Invalid app data!");
    }
  }, []);

  return (
    <>
      <ProductsManager />
    </>
  );
};
