import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { GroupInformation } from "../GroupInformation";
import { track, TRACKING } from "../../../services/Tracking";
import { GroupItem } from "../GroupItem";
import { getGroupType } from "../../../services/common";
import { failed, successfull } from "../../../Constants/toast";
import UserContext from "../../../Context/User";
import { useNavigate } from "react-router-dom";
import {
  deleteGroup,
  createGroup,
  getProductsList,
  updateGroup,
} from "../../../services/BlipFoods";
import { getUrlParams } from "../../../services/common";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../../../services/ZustandConfig";
import { AlertModal } from "../../AlertModal";
import { Button } from "@mui/material";
import { AnalyticsService as _analyticsService } from "../../../services/analyticsService/AnalyticsService";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const GroupManager = (props) => {
  const [user] = useContext(UserContext);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [selectedGroupItem, setSelectedGroupItem] = useState(0);
  const [isEditMode, setIsEditMode] = useState(true);
  const [openDeleteGroupAlert, setOpenDeleteGroupAlert] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let [
    group,
    updateGroupZS,
    removeGroupItemByIndex,
    populateProducts,
    selectedProduct,
    updateNotification,
  ] = useStore((state) => [
    state.group,
    state.updateGroupZS,
    state.removeGroupItemByIndex,
    state.populateProducts,
    state.selectedProduct,
    state.updateNotification,
  ]);

  useEffect(() => {
    if ((group && group.copyFromGroup) || (group && group.id === 0)) {
      setIsEditMode(false);
    }
  }, []);

  const handleDeleteGroupItem = (groupItemIndex) => {
    setSelectedGroupItem(groupItemIndex);
    setOpenDeleteAlert(true);
  };

  const removeGroupItem = (index) => {
    // evento de confirmação de deletar item do grupo
    updateNotification(
      true,
      successfull.deleteGroupItem.content,
      successfull.deleteGroupItem.variant
    );
    _analyticsService.Track(TRACKING.deleteGroupItemConfirmed, {
      merchantName: user.name,
      groupName: group.title,
      status: "success",
    });

    removeGroupItemByIndex(index);
    setOpenDeleteAlert(false);
    setSelectedGroupItem(0);
  };

  const addNewGroupItem = () => {
    _analyticsService.Track(TRACKING.newGroup, {
      merchantName: user.name,
    });

    updateGroupZS({
      ...group,
      groupItemViewDto: [
        ...group.groupItemViewDto,
        {
          name: "",
          titleError: "Preenchimento obrigatório",
          description: "",
          price: 0,
          priceError: "",
          isSelected: true, //indica se foi um item novo que foi adicionado
        },
      ],
    });
  };

  const handleClickCancelar = () => {
    _analyticsService.Track(TRACKING.cancelGroups, {
      merchantName: user.name,
      source: "tela de grupos",
    });

    navigate(`/?` + getUrlParams(searchParams.get("appData")));
  };

  const formatPrice = (price) => {
    return typeof price === "string"
      ? price.toString().replace(".", "").replace(",", ".")
      : price;
  };

  const validateMinimumOfOptionsToSave = () => {
    // valida se a quantidade mínima de opções foi criada antes de salvar ou atualizar um grupo
    if (group.groupType === "OneChoice" && group?.groupItemViewDto.length < 2) {
      updateNotification(
        true,
        failed.updateGroupOneChoice.content,
        failed.updateGroupOneChoice.variant
      );
      return false;
    } else if (
      group.groupType === "MultipleChoice" &&
      group?.groupItemViewDto.length < 1
    ) {
      updateNotification(
        true,
        failed.updateGroupMultipleChoiceOrExtras.content,
        failed.updateGroupMultipleChoiceOrExtras.variant
      );
      return false;
    }

    // verifica se grupo possui quantidade mínima exigida no Extras
    if (group.groupType === "Extras" && group?.minQuantity < 0) {
      updateNotification(
        true,
        failed.createGroupWithMinimumGroupExtras.content,
        failed.createGroupWithMinimumGroupExtras.variant
      );
      return false;
    }

    // verifica se grupo possui quantidade mínima exigida no multiple choices
    if (
      group?.groupType === "MultipleChoice" &&
      group?.groupItemViewDto.length < group?.minQuantity
    ) {
      updateNotification(
        true,
        failed.createGroupWithMinimumOfGroupItems.content,
        failed.createGroupWithMinimumOfGroupItems.variant
      );
      return false;
    }

    return true;
  };

  const hasAnyGroupError = () => {
    if (group && group.groupItemViewDto) {
      var hasError = group.groupItemViewDto.some(
        (option) =>
          (option.titleError && option?.titleError !== "") ||
          (option.priceError && option?.priceError !== "") ||
          (option.maxQuantityError && option?.maxQuantityError !== "")
      );

      var hasExtrasError = group.groupItemViewDto.some(
        (option) =>
          parseInt(option.maxQuantity) > 0 &&
          parseInt(option.maxQuantity) > parseInt(group?.maxQuantity)
      );

      if (
        (group.titleError && group?.titleError !== "") ||
        (group.maxQuantityError && group?.maxQuantityError !== "") ||
        (group.maxQuantityError && group?.minQuantityError !== "") ||
        hasError ||
        hasExtrasError
      ) {
        return true;
      }
    }
    return false;
  };

  const updateProductsGroups = () => {
    // Atualiza a lista de produtos com os grupos que foram atualizados/inseridos/deletados
    getProductsList(user.token).then((res) => {
      populateProducts(res.data);
    });
  };

  const handleClickUpdate = () => {
    if (validateMinimumOfOptionsToSave() && !hasAnyGroupError()) {
      var groupItems = [];
      group?.groupItemViewDto.forEach((groupItem) => {
        groupItems.push({
          ...groupItem,
          price: formatPrice(groupItem?.price),
        });
      });

      updateGroup(
        user.token,
        group.id,
        group.title,
        selectedProduct.id,
        group.maxQuantity,
        group.minQuantity,
        group.position >= 0 ? group.position : 0,
        groupItems
      )
        .then(() => {
          _analyticsService.Track(TRACKING.saveGroups, {
            merchantName: user.name,
            groupName: group.title,
            status: "success",
            source: "tela de grupos",
          });
          updateNotification(
            true,
            successfull.updateGroup.content,
            successfull.updateGroup.variant
          );
        })
        .catch((e) => {
          _analyticsService.Track(TRACKING.saveGroups, {
            merchantName: user.name,
            groupName: group.title,
            status: "failed",
            error: e.response.data.errors,
            source: "tela de grupos",
          });
          updateNotification(
            true,
            failed.updateGroup.content,
            failed.updateGroup.variant
          );
        })
        .finally(() => {
          updateProductsGroups();
          navigate(`/?` + getUrlParams(searchParams.get("appData")));
        });
    }
  };

  const handleClickSave = () => {
    if (validateMinimumOfOptionsToSave() && !hasAnyGroupError()) {
      var groupItems = [];

      group?.groupItemViewDto.forEach((groupItem) => {
        groupItems.push({
          name: groupItem?.name,
          description: groupItem?.description,
          price: formatPrice(groupItem.price),
        });
      });

      createGroup(
        user.token,
        group.title,
        group.groupType,
        selectedProduct.id,
        group.maxQuantity,
        group.minQuantity,
        group.position >= 0 ? group.position : 0,
        groupItems
      )
        .then(() => {
          _analyticsService.Track(TRACKING.saveGroups, {
            merchantName: user.name,
            groupName: group.title,
            status: "success",
            source: "tela de grupos",
          });
          updateNotification(
            true,
            successfull.createGroup.content,
            successfull.createGroup.variant
          );
        })
        .catch((e) => {
          _analyticsService.Track(TRACKING.saveGroups, {
            merchantName: user.name,
            groupName: group.title,
            status: "failed",
            error: e.response.data.errors,
            source: "tela de grupos",
          });
          updateNotification(
            true,
            failed.createGroup.content,
            failed.createGroup.variant
          );
        })
        .finally(() => {
          updateProductsGroups();
          navigate(`/?` + getUrlParams(searchParams.get("appData")));
        });
    }
  };

  const handleDeleteGroup = () => {
    deleteGroup(user.token, group.id)
      .then(() => {
        updateNotification(
          true,
          successfull.deleteGroup.content,
          successfull.deleteGroup.variant
        );

        // evento de confirmação de deletar grupo
        _analyticsService.Track(TRACKING.deleteGroupConfirmed, {
          merchantName: user.name,
          groupName: group.title,
          status: "success",
        });
      })
      .catch((e) => {
        // evento de confirmação de deletar grupo quando clica em não
        _analyticsService.Track(TRACKING.deleteGroupConfirmed, {
          merchantName: user.name,
          groupName: group.title,
          status: "failed",
          error: e.response.data.errors,
        });
        updateNotification(
          true,
          failed.deleteGroup.content,
          failed.deleteGroup.variant
        );
      })
      .finally(() => {
        updateProductsGroups();
        setOpenDeleteGroupAlert(false);
        navigate(`/?` + getUrlParams(searchParams.get("appData")));
      });
  };

  return (
    <div className="groups-container">
      <AlertModal
        open={openDeleteAlert}
        title="Exclusão do complemento"
        content="Atenção! Se você excluir essa opção, não poderá mais recuperá-la. Você tem certeza disso?"
        handleAgreeEvent={() => removeGroupItem(selectedGroupItem)}
        handleDisagreeEvent={() => setOpenDeleteAlert(false)}
      />
      <AlertModal
        open={openDeleteGroupAlert}
        title="Exclusão do Grupo de Complementos"
        content="Atenção! Se você excluir esse grupo, não poderá mais recuperá-lo. Você tem certeza disso?"
        handleAgreeEvent={() => handleDeleteGroup()}
        handleDisagreeEvent={() => setOpenDeleteGroupAlert(false)}
      />
      <GroupInformation
        id={group?.id}
        key={group?.id}
        type={getGroupType(group?.groupType)}
        title={group?.title}
        qtdMinima={group?.minQuantity}
        qtdMaxima={group?.maxQuantity}
        position={group?.position}
        disabled={props.disabled}
      />

      <div className="header-container">
        <span className="title">Opções</span>
        <div className="button-container">
          <Button
            variant="text"
            startIcon={<AddOutlinedIcon />}
            onClick={() => addNewGroupItem()}
          >
            Adicionar opção
          </Button>
        </div>
      </div>

      {group?.groupItemViewDto?.map((groupItem, index) => {
        return (
          <GroupItem
            id={groupItem.id}
            key={groupItem.id}
            indexValue={index}
            type={group?.groupType}
            title={groupItem.name}
            price={groupItem.price}
            description={groupItem.description}
            qtdMaxima={groupItem.maxQuantity}
            deleteEvent={() => handleDeleteGroupItem(index)}
          />
        );
      })}

      {!isEditMode ? (
        <div className="page-buttons-container">
          <div className="buttons-container-right">
            <div className="page-buttons">
              <Button
                variant="outlined"
                startIcon={<CloseOutlinedIcon />}
                onClick={() => handleClickCancelar()}
                style={{ marginRight: 14 }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                disabled={hasAnyGroupError()}
                startIcon={<AddOutlinedIcon />}
                onClick={() => handleClickSave()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-buttons-container delete-button">
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={() => setOpenDeleteGroupAlert(true)}
          >
            Apagar
          </Button>

          <div className="page-buttons">
            <Button
              variant="outlined"
              startIcon={<CloseOutlinedIcon />}
              onClick={() => handleClickCancelar()}
              style={{ marginRight: 14 }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={hasAnyGroupError()}
              startIcon={<AddOutlinedIcon />}
              onClick={() => handleClickUpdate()}
            >
              Salvar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
