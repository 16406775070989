import React, { useState } from "react";

import UserContext from "../../Context/User";

export const ContextWrapper = (props) => {
  const [user, setUser] = useState({
    token: "",
    name: "",
    image: "",
    merchantId: "",
    botKey: "",
    contactIdentity: "",
  });

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
