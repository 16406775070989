import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../services/ZustandConfig";
import { TextArea } from "../../TextArea";
import "./style.scss";

export const GroupInformation = (props) => {
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [qtdMaxima, setQtdMaxima] = useState();
  const [qtdMinima, setQtdMinima] = useState();
  const [position, setPosition] = useState();
  const [selectOptions] = useState([
    { value: 1, name: "Escolha Única" },
    { value: 2, name: "Escolha Múltipla" },
    { value: 3, name: "Extras" },
  ]);
  let [group, updateGroupZS] = useStore((state) => [
    state.group,
    state.updateGroupZS,
  ]);

  const getGroupTypeById = (groupType) => {
    if (groupType === 1) return "OneChoice";
    if (groupType === 2) return "MultipleChoice";
    if (groupType === 3) return "Extras";
  };

  useEffect(() => {
    const errorMessageTitle = props?.title ? "" : "Preenchimento obrigatório";
    setTitle({ value: props?.title, error: errorMessageTitle });
    setQtdMaxima({ value: props?.qtdMaxima, error: "" });
    setQtdMinima({ value: props?.qtdMinima, error: "" });
    setPosition({ value: props?.position, error: "" });
    setType(props?.type);
  }, []);

  const handleTitleChange = (value) => {
    const errorMessage = value ? "" : "Preenchimento obrigatório";
    setTitle({ value, error: errorMessage });

    // atualiza o contexto com as alterações do front-end
    updateGroupZS({
      ...group,
      title: value,
      titleError: errorMessage,
    });

    return !!errorMessage;
  };

  const handleQtdMaximaChange = (value) => {
    let errorMessage;

    if (value) {
      errorMessage =
        value && value >= 0 ? "" : "Insira um valor maior ou igual a 0";
      if (value > 0 && qtdMinima.value > 0 && value <= qtdMinima.value) {
        errorMessage = "Qtd. máxima deve ser maior que Qtd. mínima";
      }
    } else {
      errorMessage = "";
    }

    setQtdMaxima({ value, error: errorMessage });

    var newGroupItemViewDto = [];
    newGroupItemViewDto = group.groupItemViewDto.map((element) => {
      return { ...element, maxQuantity: value };
    });

    // atualiza o contexto com as alterações do front-end
    updateGroupZS({
      ...group,
      maxQuantity: value,
      maxQuantityError: errorMessage,
      groupItemViewDto: [...newGroupItemViewDto],
    });

    return !!errorMessage;
  };

  const handleQtdMinimaChange = (value) => {
    let errorMessage;
    if (value) {
      errorMessage =
        value && value >= 0 ? "" : "Insira um valor maior ou igual a 0";
    } else {
      errorMessage = "";
    }
    setQtdMinima({ value, error: errorMessage });

    // atualiza o contexto com as alterações do front-end
    updateGroupZS({
      ...group,
      minQuantity: value,
      minQuantityError: errorMessage,
    });

    return !!errorMessage;
  };

  const handlePositionChange = (value) => {
    let errorMessage;
    if (value) {
      errorMessage =
        value && value >= 0 ? "" : "Insira um valor maior ou igual a 0";
    } else {
      errorMessage = "";
    }
    setPosition({ value, error: errorMessage });

    // atualiza o contexto com as alterações do front-end
    updateGroupZS({
      ...group,
      position: value,
    });

    return !!errorMessage;
  };

  const handleSelectChange = (value) => {
    setType(Number(value));
    // altera o tipo de grupo que foi selecionado no modo de edição
    updateGroupZS({
      ...group,
      groupType: getGroupTypeById(value),
    });
  };

  return (
    <div className="groups">
      <div className="group-type">
        <FormControl fullWidth>
          <InputLabel>Tipo</InputLabel>
          <Select
            value={props?.type}
            label="Tipo"
            sx={{ height: 46 }}
            disabled={props.disabled}
            onChange={(e) => handleSelectChange(e.target.value)}
          >
            {selectOptions.map((option) => {
              return <MenuItem value={option?.value}>{option?.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>

      <div className="group-title">
        <TextArea
          placeholder="Nome do complemento"
          label="Título"
          text={title?.value}
          maxLength={50}
          emitChange={(value) => handleTitleChange(value)}
          error={!!title?.error}
          helperText={title?.error}
        />
      </div>

      {type === 3 || type === 2 ? (
        <div className="group-min-quantity">
          <TextArea
            placeholder="Qtd. mínima de escolhas"
            label="Qtd. mínima de escolhas"
            text={qtdMinima?.value}
            maxLength={128}
            emitChange={(value) => handleQtdMinimaChange(value)}
            error={!!qtdMinima?.error}
            helperText={qtdMinima?.error}
          />
        </div>
      ) : (
        <div />
      )}

      {type === 3 || type === 2 ? (
        <div className="group-max-quantity">
          <TextArea
            placeholder="Qtd. máxima de escolhas"
            label="Qtd. máxima de escolhas"
            text={qtdMaxima?.value}
            maxLength={128}
            emitChange={(value) => handleQtdMaximaChange(value)}
            error={!!qtdMaxima?.error}
            helperText={qtdMaxima?.error}
          />
        </div>
      ) : (
        <div />
      )}

      <div className="group-position">
        <TextArea
          type="number"
          label="Posição a ser exibida"
          placeholder="Digite a posição"
          text={position?.value}
          emitChange={(value) => handlePositionChange(value)}
          error={!!position?.error}
          helperText={position?.error}
          min={0}
        />
      </div>
    </div>
  );
};
