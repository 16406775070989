import React, { useContext, useRef } from "react";
import imageIcon from "../../assets/images/image.svg";
import uploadIcon from "../../assets/images/upload.svg";
import UserContext from "../../Context/User";
import { getFileStorageUrl, uploadFileToBlip } from "../../services/Blip";
import "./style.scss";

export const ImageUploader = (props) => {
  const [user, setUser] = useContext(UserContext);
  const imageInputRef = useRef();

  const handleClick = () => {
    imageInputRef.current.click();
  };

  const EmptyImage = () => {
    return (
      <div
        className="empty-image"
        style={{ borderColor: props?.error ? "red" : "#647588" }}
      >
        <img src={imageIcon} />
      </div>
    );
  };

  const uploadImage = (image, organization) => {
    getFileStorageUrl(user.botKey, organization).then((res) => {
      uploadFileToBlip(user.botKey, res.data.resource, image.files[0])
        .then((res) => {
          props.newImageEvent(res.data.mediaUri);
        })
        .finally(() => clearImage());
    });
  };

  const clearImage = () => {
    imageInputRef.current.value = "";
  };

  return (
    <div className="image-upload-container">
      <div className="image-circle" onClick={handleClick}>
        {props?.image ? (
          <img className="product-image" src={props?.image} />
        ) : (
          <EmptyImage />
        )}

        <div
          className="upload-icon"
          style={{ borderColor: props?.error ? "red" : "#647588" }}
        >
          <img src={uploadIcon} />
        </div>

        <input
          ref={imageInputRef}
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => uploadImage(e.target, user.organization)}
        />
      </div>
      <span className="error-message">{props?.error}</span>
    </div>
  );
};
