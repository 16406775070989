import React, { useContext, useEffect, useState } from "react";
import "./style.scss";

import UserContext from "../../../Context/User";
import { getGroupsProduct } from "../../../services/BlipFoods";
import { Divider } from "../../Divider";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getUrlParams } from "../../../services/common";
import { track, TRACKING } from "../../../services/Tracking";
import { useStore } from "../../../services/ZustandConfig";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { GroupList } from "../GroupList";
import { createRef } from "react";
import { AnalyticsService as _analyticsService } from "../../../services/analyticsService/AnalyticsService";

export const ExistentGroup = (product) => {
  const [user, setUser] = useContext(UserContext);
  const [productsArray, setProductsArray] = useState([]);
  const [selectedProductOption, setSelectedProductOption] = useState([]);
  const [groupsProduct, setGroupsProduct] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState(0);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let [allProducts, group, updateGroupZS] = useStore((state) => [
    state.allProducts,
    state.group,
    state.updateGroupZS,
  ]);

  useEffect(() => {
    createProductArray();
  }, []);

  const getGroupType = (group) => {
    let option = group.groupItemViewDto.length < 1 ? " opção" : " opções";

    if (group.groupType === "OneChoice") {
      return "Escolha Única - " + group.groupItemViewDto.length + option;
    }
    if (group.groupType === "MultipleChoice") {
      return "Escolha Multipla - " + group.groupItemViewDto.length + option;
    }
    if (group.groupType === "Extras") {
      return "\nExtras - " + group.groupItemViewDto.length + option;
    }
  };

  const handleSelectChange = (value) => {
    document
      .getElementById("groupSpinner")
      ?.classList.remove("hidden-group-spinner");
    value = parseInt(value);

    setGroupsProduct([]);

    getGroupsProduct(user.token, value)
      .then((res) => {
        res.data.forEach((group) => (group.ref = createRef()));
        setGroupsProduct(res.data);
      })
      .finally(() => {
        document
          .getElementById("groupSpinner")
          ?.classList.add("hidden-group-spinner");
      });
    setSelectedProductOption(value);
    setSelectedGroupOption(0);
  };

  // * Filtra apenas produtos que possuam grupos
  const createProductArray = () => {
    var productArray = [];

    if (allProducts && allProducts?.length > 0) {
      allProducts?.forEach((product) => {
        if (product.groupViewDto && product.groupViewDto.length > 0) {
          return productArray.push({ value: product.id, name: product.name });
        }
        return false;
      });
      setSelectDisabled(false);
      handleSelectChange(productArray[0].value);
    }

    if (productArray.length == 0) {
      productArray.push({ value: 0, name: "Selecione" });
      setSelectDisabled(true);
    }

    setProductsArray(productArray);
    setSelectedProductOption(productArray[0].value);
  };

  const handleRadioButtonClick = (groupId) => {
    var groupSelected = groupsProduct.find((group) => group.id == groupId);

    setSelectedGroupOption(groupSelected);

    // * copia todos os itens do grupo que foi selecionado para clonar
    var groupItems = [];
    groupSelected.groupItemViewDto.forEach((groupItem) => {
      groupItems.push({
        id: groupItem.id,
        name: groupItem.name,
        titleError: "",
        description: groupItem.description,
        price: groupItem.price,
        priceError: "",
        maxQuantity: groupItem.maxQuantity,
        maxQuantityError: "",
        isSelected: true,
      });
    });

    updateGroupZS({
      copyFromGroup: true,
      title: groupSelected.title,
      titleError: "",
      maxQuantity: groupSelected.maxQuantity,
      maxQuantityError: "",
      minQuantity: groupSelected.minQuantity,
      minQuantityError: "",
      groupType: groupSelected.groupType,
      groupItemViewDto: groupItems,
    });
  };

  const handleClickCancelar = () => {
    _analyticsService.Track(TRACKING.cancelGroups, {
      merchantName: user.name,
      source: "tela de grupos",
    });

    var params = getUrlParams(searchParams.get("appData"));
    navigate(`/?` + params);
  };

  const handleAddClick = () => {
    if (selectedGroupOption !== 0) {
      var choice = "";
      if (group.groupType === "OneChoice") {
        choice = "Escolha Única";
      } else if (group.groupType === "MultipleChoice") {
        choice = "Escolha Multipla";
      } else if (group.groupType === "Extras") {
        choice = "Extras";
      }

      _analyticsService.Track(TRACKING.addExistingGroup, {
        merchantName: user.name,
        type: "existente",
        choices: choice,
      });

      navigate(`/groups/edit/0/?` + getUrlParams(searchParams.get("appData")));
    }
  };

  return (
    <div>
      <div className="groups-existent" id="existentGroup">
        <div>
          <span>Mostrar Grupos de Complementos do produto:</span>
          <div className="inside-select-product">
            <FormControl fullWidth>
              <InputLabel>Produto</InputLabel>
              <Select
                value={selectedProductOption}
                label="Produto"
                onChange={(e) => handleSelectChange(e.target.value)}
              >
                {productsArray.map((option, key) => {
                  return (
                    <MenuItem value={option?.value} key={key}>
                      {option?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <span>Escolha um grupo:</span>
          <div className="select-group">
            <div id="groupSpinner">
              <CircularProgress />
            </div>
            <GroupList
              groups={groupsProduct}
              radioClick={(groupId) => handleRadioButtonClick(groupId)}
            />
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <div className="existent-buttons">
          <Button
            variant="outlined"
            startIcon={<CloseOutlinedIcon />}
            onClick={() => handleClickCancelar()}
            style={{ marginRight: 14 }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={selectedGroupOption === 0}
            startIcon={<AddOutlinedIcon />}
            onClick={() => handleAddClick()}
          >
            Adicionar
          </Button>
        </div>
      </div>
    </div>
  );
};
