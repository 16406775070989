import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import base64 from "react-native-base64";
import { useSearchParams } from "react-router-dom";
import { Drawer } from "../../Components/Drawer";
import { EmptyContent } from "../../Components/EmptyContent";
import { FullScreenLoader } from "../../Components/FullScreenLoader";
import { ProductDetail } from "../../Components/ProductDetail";
import { ProductListItem } from "../../Components/ProductListItem";
import { AKV } from "../../Constants/appKeys";
import { emptyProduct } from "../../Constants/product";
import UserContext from "../../Context/User";
import { AnalyticsService as _analyticsService } from "../../services/analyticsService/AnalyticsService";
import {
  getMerchantInfo,
  getProductsList,
  getTemporaryToken
} from "../../services/BlipFoods";
import { TRACKING } from "../../services/Tracking";
import { useStore } from "../../services/ZustandConfig";
import "./style.scss";

export const ProductsManager = () => {
  const [user, setUser] = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [openLoader, setOpenLoader] = useState(true);
  let [
    allProducts,
    selectedProduct,
    populateAllProducts,
    updateSelectedProduct,
    openDrawer,
    setOpenDrawer,
  ] = useStore((state) => [
    state.allProducts,
    state.selectedProduct,
    state.populateProducts,
    state.updateSelectedProduct,
    state.openDrawer,
    state.setOpenDrawer,
  ]);

  useEffect(() => {
    getBasicInfo();
  }, []);

  const getBasicInfo = () => {
    getTemporaryToken(AKV.REACT_APP_BLIP_FOODS_TOKEN)
      .then((res) => {
        const merchantBase64 = searchParams.get("appData").replace(" ", "+");
        const appData = JSON.parse(base64.decode(merchantBase64));
        getMerchantInfo(appData.profile.email, res.data.temporaryToken)
          .then((userResponse) => {
            getProductsList(userResponse.data.token)
              .then((res) => {
                _analyticsService.Track(TRACKING.openHome, {
                  merchantName: userResponse.data.name,
                  numberProduct: res.data.length,
                });
                const productsList = res.data.map((product) => {
                  return {
                    ...product,
                    isSelected: false,
                  };
                });
                populateAllProducts(productsList);
                setOpenLoader(false);
              })
              .catch(() => setOpenLoader(false));

            setUser((current) => {
              const userData = {
                token: userResponse.data.token,
                name: userResponse.data.name,
                merchantId: userResponse.data.id,
                botKey: userResponse.data.botKey,
                organization: userResponse.data.organization
              };
              return {
                ...current,
                ...userData,
              };
            });
          })
          .catch(() => setOpenLoader(false));
      })
      .catch(() => setOpenLoader(false));
  };

  const openDetailTrack = (source, productName, productId) => {
    _analyticsService.Track(TRACKING.openDetail, {
      merchantName: user.name,
      source,
      productName,
      productId,
    });
  };

  const openDetailExistTrack = (source, productName, productId) => {
    _analyticsService.Track(TRACKING.openDetailExist, {
      merchantName: user.name,
      source,
      productName,
      productId,
    });
  };

  const handleActionClick = (index) => {
    openDetailExistTrack(
      "edit",
      allProducts[index].name,
      allProducts[index].id
    );

    updateSelectedProduct(allProducts[index]);
    setOpenDrawer(true);
  };

  const addNewProduct = () => {
    openEmptyDetail();
  };

  const openEmptyDetail = () => {
    _analyticsService.Track(TRACKING.newProduct, {
      merchantName: user.name,
    });
    openDetailTrack("create", "", 0);
    emptyProduct.createdAt = Date.now();
    updateSelectedProduct(emptyProduct);
    setOpenDrawer(true);
  };

  return (
    <div className="main-container-product-manager">
      <FullScreenLoader open={openLoader} />
      <div className="left-side-container">
        <div className="button-container">
          <Button
            variant="contained"
            onClick={() => addNewProduct()}
            startIcon={<AddRoundedIcon sx={{ fill: "white" }} />}
          >
            Novo produto
          </Button>
        </div>
        <div className="list-view-header">
          <div className="small-item">Publicado</div>
          <div className="small-item">ID</div>
          <div className="large-item">Produto</div>
          <div className="small-item">Preço</div>
        </div>
        <div className="list-view div-with-scroll" id="scrollDiv">
          {allProducts && allProducts?.length > 0 ? (
            allProducts?.map((product, index) => {
              return (
                <ProductListItem
                  isPublished={product?.isActive}
                  id={product?.id}
                  name={product?.name}
                  value={product?.value}
                  imageUrl={product?.imageUrl}
                  isSelected={product?.isSelected}
                  salePrice={product?.salePrice}
                  key={product?.id}
                  handleActionClick={() => handleActionClick(index)}
                />
              );
            })
          ) : (
            <div className="empty-content-container">
              <EmptyContent message="Nenhum produto adicionado ainda..." />
            </div>
          )}
        </div>
      </div>

      <Drawer
        position={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <ProductDetail product={selectedProduct}></ProductDetail>
      </Drawer>
    </div>
  );
};
