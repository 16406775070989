import axios from "axios";
import { _logService } from "../../Configs/LogConfig";
import { Json } from "../../utils/Json";
import { BlipCommandHeader } from "../base/models/BlipCommandHeader";
import { getMsgingUrl } from "../Blip";
import { GetConfigurationRequest } from "./models/getConfiguration/GetConfigurationRequest";
import { GetStatesResquest as GetStatesRequest } from "./models/getStates/GetStatesRequest";

const _logger = _logService.getChildCategory("Configuration");
const msging = "@msging.net";
const axiosInstance = axios.create();

async function GetConfigurationsAsync(botKey, userMail, organization, from, pp) {
  let log = _logger.getChildCategory("Get");

  try {
    var request = new GetConfigurationRequest(userMail, from, pp);
    const headers = new BlipCommandHeader(botKey);
    log.info(`Request configurations`);
    log.debug(Json.StringifyFormat(request), "Request body.");
    axiosInstance.defaults.headers.common["Authorization"] = botKey;
    const { data, status } = await axiosInstance.post(`https://${organization}/commands`,
      request,
      {
        headers: headers,
      }
    );

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`Configurations received with status code ${status}.`);
    return data;
  } catch (exception) {
    log.error(`Error on request configurations`, () => exception);

    throw exception;
  }
}

async function GetApplicationConfiguration(botKey, userMail, organization) {
  var response = await GetConfigurationsAsync(botKey, userMail, organization);

  var application = JSON.parse(response.resource.Application);

  return application;
}

async function GetRouterIdentifier(botKey, userMail, organization) {
  var application = await GetApplicationConfiguration(botKey, userMail, organization);

  return application.identifier + msging;
}

async function GetFlowIdentifier(botKey, userMail, skillIdentifier, organization) {
  let log = _logger.getChildCategory("GetFlowIdentifier");
  try {
    var routerIdentifier = await GetRouterIdentifier(botKey, userMail, organization);

    log.info("Request flow identifier");

    var response = await GetConfigurationsAsync(
      botKey,
      userMail,
      organization,
      skillIdentifier,
      routerIdentifier,
    );

    var application = JSON.parse(response.resource.Application);

    var flowId = application.settings.flow.id;

    log.info(flowId, "Response body");

    return flowId;
  } catch (exception) {
    _logger.error(`Error on request flow identifier`, () => exception);

    throw exception;
  }
}

async function GetStatesAsync(botKey, userMail, skillName, organization) {
  let log = _logger.getChildCategory("GetStates");

  try {
    var request = new GetStatesRequest(userMail, skillName);

    log.info(`Request states of ${skillName}`);
    log.debug(Json.StringifyFormat(request), "Request body.");
    axiosInstance.defaults.headers.common["Authorization"] = botKey;
    const { data, status } = await axiosInstance.post(
      `${getMsgingUrl(organization)}/commands`,
      request,
      {
        headers: new BlipCommandHeader(botKey),
      }
    );

    log.debug(Json.StringifyFormat(data), "Response body.");
    log.info(`States of of ${skillName} received with status code ${status}.`);
    return data;
  } catch (exception) {
    log.error(`Error on request configurations`, () => exception);
    throw exception;
  }
}

export const ConfigurationService = {
  GetConfigurationsAsync,
  GetStatesAsync,
  GetApplicationConfiguration,
  GetRouterIdentifier,
  GetFlowIdentifier,
};
