import React from "react";
import { ExistentCategory } from "../../Components/Category/ExistentCategory";
import "./style.scss";

export const Categories = () => {
  return (
    <div className="page-container">
      <ExistentCategory />
    </div>
  );
};
