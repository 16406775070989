import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import "./style.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CategoryContainer = ({ categories, error, categoryChange }) => {
  const RenderError = () => {
    if (error) {
      return (
        <div className="category-error-container">
          <InfoOutlinedIcon
            fontSize="small"
            style={{ marginRight: "3px", fill: "red" }}
          />
          <span className="category-error-message">{error}</span>
        </div>
      );
    } else <></>;
  };
  return (
    <div>
      <FormControl
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #b9cbd3",
          padding: "10px",
          height: "120px",
          overflow: "auto",
        }}
        style={{ borderColor: error ? "red" : "#b9cbd3" }}
      >
        <FormGroup
          className="category-items"
          sx={{ display: "grid", gridTemplateColumns: "50% 50%" }}
        >
          {categories?.map((category, index) => {
            return (
              <div className="category-checkbox mb1" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={category?.selected}
                      onChange={() => categoryChange(index)}
                      name={category?.name}
                    />
                  }
                  label={category?.name}
                />
              </div>
            );
          })}
        </FormGroup>
      </FormControl>
      <RenderError />
    </div>
  );
};
