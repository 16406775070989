import React from "react";
import "./style.scss";

export const EmptyContent = ({ message }) => {
  return (
    <div className="main-container-empty-content">
      <span className="info">{message}</span>
    </div>
  );
};
