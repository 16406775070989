import React from "react";
import "./style.scss";
import { convertToReal, addLeadingZeros } from "../../services/common";
import { Avatar } from "@mui/material";

export const ProductListItem = (props) => {
  return (
    <div
      className={
        props?.isSelected
          ? "product-item is-selected slide-in-left"
          : "product-item slide-in-left"
      }
      onClick={() => props?.handleActionClick()}
    >
      <div className="small-item">
        {props?.isPublished ? (
          <div className="is-published"></div>
        ) : (
          <div className="not-published"></div>
        )}
      </div>
      <div className="small-item product-id">
        {addLeadingZeros(props?.id + "", 6)}
      </div>
      <div className="large-item">
        <Avatar
          alt="Foto do Produto"
          src={props?.imageUrl}
          sx={{ height: 56, width: 56 }}
        />
        <div className="f6 lh-copy product-name">{props?.name}</div>
      </div>
      <div className="price-container small-item">
        {props?.salePrice > 0 ? (
          <>
            <div className="sale-price">{convertToReal(props?.value)}</div>
            <div className="price">{convertToReal(props?.salePrice)}</div>
          </>
        ) : (
          <div className="price">{convertToReal(props?.value)}</div>
        )}
      </div>
    </div>
  );
};
