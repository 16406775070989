import React, { useEffect, useState } from "react";
import { GroupManager } from "../../../Components/Groups/GroupManager";
import "./style.scss";
import { ExistentGroup } from "../../../Components/Groups/ExistentGroup";
import { useStore } from "../../../services/ZustandConfig";

export const AddGroup = () => {
  const [createMode, setCreateMode] = useState(false);
  //const [group, setGroup] = useContext(GroupContext);
  let [group, updateGroupZS] = useStore((state) => [
    state.group,
    state.updateGroupZS,
  ]);
  useEffect(() => {
    document.getElementById("radioExisting").checked = true;
  }, []);

  const setNewGroup = (createMode) => {
    setCreateMode(createMode);

    updateGroupZS({
      id: 0,
      title: "",
      titleError: "Preenchimento obrigatório",
      maxQuantity: 0,
      maxQuantityError: "",
      minQuantity: 0,
      minQuantityError: "",
      groupType: "OneChoice",
      groupItemViewDto: [
        {
          id: Date.now(),
          name: "",
          titleError: "Preenchimento obrigatório",
          description: "",
          price: 0,
          priceError: "",
          maxQuantity: "",
          maxQuantityError: "",
          isSelected: true,
        },
        {
          id: Date.now() + 1,
          name: "",
          titleError: "Preenchimento obrigatório",
          description: "",
          price: 0,
          priceError: "",
          maxQuantity: "",
          maxQuantityError: "",
          isSelected: true,
        },
      ],
    });
  };

  return (
    <div className="add-new-group-container page-container">
      <span className="page-header">Novo Grupo de Complementos</span>

      <div className="radio-button-container">
        <div
          onChange={(event) => setNewGroup(false)}
          className="radio-button-item"
        >
          <input
            type="radio"
            value="radio1"
            name="pageOptions"
            id="radioExisting"
          />
          <label className="radio-button-label" htmlFor="radioExisting">
            Existente
          </label>
        </div>

        <div
          onChange={(event) => setNewGroup(true)}
          className="radio-button-item"
        >
          <input
            type="radio"
            value="radio2"
            name="pageOptions"
            id="radioCreate"
          />
          <label className="radio-button-label" htmlFor="radioCreate">
            Novo
          </label>
        </div>
      </div>

      {createMode ? (
        <div>
          <div>
            <GroupManager disabled={false} />
          </div>
        </div>
      ) : (
        <div>
          <ExistentGroup />
        </div>
      )}
    </div>
  );
};
