import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from "@mui/material";
import React from "react";
import { Divider } from "../../Divider";
import { EmptyContent } from "../../EmptyContent";
import "./style.scss";

export const GroupContainer = ({
  groups,
  openGroupEdition,
  openDeleteGroupModal,
  itemId,
}) => {
  const textGroupType = (typeGroup) => {
    if (typeGroup === "OneChoice") {
      return "Escolha única";
    }

    if (typeGroup === "MultipleChoice") {
      return "Escolha multipla";
    }

    if (typeGroup === "Extras") {
      return "Extras";
    }
  };

  const DisplayNoGroupsMessage = () => {
    return itemId !== 0 ? (
      <EmptyContent message="Nenhum grupo adicionado ainda..." />
    ) : (
      <EmptyContent message="Salve o produto para adicionar grupos de complementos" />
    );
  };

  const hasGroup = () => {
    const groupsFiltered = groups.filter(
      (group) => group?.groupType !== "Observation"
    );
    return groupsFiltered.length > 0;
  };

  return (
    <div className="group-container">
      {hasGroup() ? (
        <List dense={true}>
          {groups?.map((group, index) => {
            if (group?.groupType !== "Observation") {
              return (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={group?.title}
                      secondary={textGroupType(group?.groupType)}
                    />

                    <ListItemSecondaryAction>
                      <Tooltip placement="left" title="Editar este grupo">
                        <IconButton
                          edge="end"
                          aria-label="Editar grupo"
                          onClick={() => openGroupEdition(group)}
                          sx={{ marginRight: "4px" }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip placement="left" title="Excluir este grupo">
                        <IconButton
                          aria-label="Excluir grupo"
                          onClick={() => openDeleteGroupModal(group)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider lenght={groups.lenght} index={index} />
                </div>
              );
            }
          })}
        </List>
      ) : (
        <DisplayNoGroupsMessage />
      )}
    </div>
  );
};
