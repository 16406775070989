export const TRACKING = {
  saveProduct: "blippacks-management-bf-product-salved",
  openHome: "blippacks-management-bf-opened",
  newProduct: "blippacks-management-bf-newproduct",
  openDetail: "blippacks-management-bf-product-opened",
  openDetailExist: "blippacks-management-bf-product-opened",
  publish:
    "blippacks-management-productmanagement-bf-productlist-publishproduct",
  deleteProductClicButton: "blippacks-management-bf-product-deleteproduct",
  cancelProductEdition: "blippacks-management-bf-product-canceled",
  deleteProductConfirmed:
    "blippacks-management-bf-product-deleteproduct-confirmed",
  newGroup: "blippacks-management-bf-group-addoption",
  newGroupProductDetail: "blippacks-management-bf-product-newgroup",
  editGroup: "blippacks-management-bf-product-editgroup",
  deleteGroupConfirmed: "blippacks-management-bf-group-deletegroup-confirmed",
  saveGroups: "blippacks-management-bf-group-salved",
  cancelGroups: "blippacks-management-bf-group-canceled",
  addExistingGroup: "blippacks-management-bf-group-add",
  deleteGroupItemConfirmed:
    "blippacks-management-bf-group-deleteitem-confirmed",
  newCategoryProductDetail: "blippacks-management-bf-category-newcategory",
  editCategoryProductDetail: "blippacks-management-bf-product-editcategory",
  openPageCategory: "blippacks-management-bf-categoryopened",
  addNewCategory: "blippacks-management-bf-category-addcategory",
  saveNewCategory: "blippacks-management-bf-category-salved",
  cancelCategory: "blippacks-management-bf-category-canceled",
  deleteCategory: "blippacks-management-bf-category-delete",
  deleteConfirmCategory:
    "blippacks-management-bf-category-deletecategory-confirmed",
};
