import { createContext } from "react";

const UserContext = createContext([
  {
    token: "",
    name: "",
    image: "",
    merchantId: "",
    botKey: "",
    contactIdentity: "",
    organization: ""
  },
  () => {},
]);

export default UserContext;
