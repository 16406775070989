import base64 from "react-native-base64";

export const Base64Encode = (data) => {
  return base64.encode(data);
};

export const Base64Decode = (data) => {
  return base64.decode(data);
};

export const GetBotKey = (shortName, accessKey) => {
  return "Key " + Base64Encode(`${shortName}:${Base64Decode(accessKey)}`);
};
