import axios from "axios";

export const getFileStorageUrl = (botKey, organization) => {
  return axios.post(
    `${getMsgingUrl(organization)}/commands`,
    {
      id: "{{$guid}}",
      to: "postmaster@media.msging.net",
      method: "get",
      uri: "/upload-media-uri",
    },
    {
      headers: {
        Authorization: botKey,
      },
    }
  );
};

export const uploadFileToBlip = (botKey, url, formData) => {
  return axios.post(url, formData, {
    headers: {
      Authorization: botKey,
      "Content-Type": formData.type,
    },
  });
};

export const getMsgingUrl = (organization) => {
  return `https://${organization}`;
}
