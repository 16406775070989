export const emptyProduct = {
  id: 0,
  name: "",
  description: "",
  value: 0,
  imageUrl: "",
  isSelected: true,
  isActive: true,
  salePrice: 0,
  key: 0,
  peopleToServe: 0,
  createdAt: Date.now(),
  categories: [],
};
