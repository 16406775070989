import { Alert, Slide, Snackbar } from "@mui/material";
import React from "react";
import "./style.scss";

export const Notification = ({
  content,
  variant,
  showNotification,
  handleClose,
}) => {
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }
  return (
    <Snackbar
      open={showNotification}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={handleClose}
        severity={variant}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};
