import { Drawer as MuiDrawer } from "@mui/material";
import React from "react";
import "./style.scss";

export const Drawer = ({ open, position, children, onClose }) => {
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose();
  };

  return (
    <MuiDrawer
      anchor={position}
      open={open}
      onClose={toggleDrawer(open)}
      sx={{ boxShadow: "none !important", backgroundColor: "unset !important" }}
    >
      {children}
    </MuiDrawer>
  );
};
