import { TextField } from "@mui/material";
import React, { useState } from "react";

export const TextArea = (props) => {
  const [inputText, setInputText] = useState("");

  const handleClick = (text) => {
    setInputText(text);
    props.emitChange(text);
  };

  if (props.type === "number") {
    return (
      <TextField
        type={"number"}
        inputProps={{
          min: props.min,
          maxLength: props?.maxLength,
          sx: { height: 46 },
        }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        label={props?.label}
        placeholder={props?.placeholder}
        value={props?.text}
        onChange={(evt) => handleClick(evt.target.value)}
        error={props?.error}
        helperText={props?.helperText}
      />
    );
  } else {
    return (
      <TextField
        size={props?.multiline ? "small" : ""}
        inputProps={{
          maxLength: props?.maxLength,
          sx: { height: 46 },
        }}
        InputLabelProps={{ shrink: true }}
        multiline={props?.multiline || false}
        maxRows={props?.multiline ? 4 : 1}
        fullWidth
        label={props?.label}
        placeholder={props?.placeholder}
        value={props?.text}
        onChange={(evt) => handleClick(evt.target.value)}
        error={props?.error}
        helperText={props?.helperText}
      />
    );
  }
};
