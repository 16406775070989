import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { AlertModal } from "../../AlertModal";
import { getUrlParams } from "../../../services/common";
import {
  createCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "../../../services/BlipFoods";
import UserContext from "../../../Context/User";
import { useNavigate, useSearchParams } from "react-router-dom";

import { track, TRACKING } from "../../../services/Tracking";
import { Button, IconButton } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { TextArea } from "../../TextArea";
import { useStore } from "../../../services/ZustandConfig";
import { successfull, failed } from "../../../Constants/toast";
import { AnalyticsService as _analyticsService } from "../../../services/analyticsService/AnalyticsService";

export const ExistentCategory = () => {
  const [user, setUser] = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [idCategorySelect, setIdCategorySelect] = useState(0);
  const [indexCategorySelect, setIndexCategorySelect] = useState(0);
  const [titlePage, setTitlePage] = useState("");
  const [newCategory, setNewCategory] = useState([]);
  const [listCategoryErrors, setListCategoryErrors] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let [updateNotification] = useStore((state) => [state.updateNotification]);

  const isNewCategory = searchParams.get("mode");

  useEffect(() => {
    setListCategoryErrors([]);
    if (isNewCategory === "new") {
      setTitlePage("Nova Categoria de Produtos");
      setNewCategory((current) => {
        return [
          ...current,
          {
            name: "",
            position: 1,
            nameError: "Preenchimento obrigatório",
            positionError: "",
            isChange: false,
          },
        ];
      });
    } else {
      setTitlePage("Categoria de Produtos");
    }
    getCategory(user.token).then((res) => {
      _analyticsService.Track(TRACKING.openPageCategory, {
        merchantName: user.name,
        numbercategory: res.data.length,
      });

      res.data.forEach((category) => {
        category.nameError = "";
        category.positionError = "";
        category.isChange = false;
      });
      setCategories(res.data);
    });
  }, []);

  const handleDeleteCategory = (idCategory, index) => {
    _analyticsService.Track(TRACKING.deleteCategory, {
      merchantName: user.name,
    });
    setIdCategorySelect(idCategory);
    setIndexCategorySelect(index);
    setOpenDeleteAlert(true);
  };

  const removeCategory = () => {
    setOpenDeleteAlert(false);
    deleteCategory(user.token, idCategorySelect)
      .then((res) => {
        _analyticsService.Track(TRACKING.deleteConfirmCategory, {
          merchantName: user.name,
          categoryName: categories[indexCategorySelect].name,
          status: "success",
        });
        updateNotification(
          true,
          successfull.deleteCategory.content,
          successfull.deleteCategory.variant
        );
        setCategories((current) => {
          return [
            ...current.slice(0, indexCategorySelect),
            ...current.slice(indexCategorySelect + 1),
          ];
        });
      })
      .catch((e) => {
        let result = e.response.data.includes(
          "The category has one or more products"
        );
        if (result) {
          _analyticsService.Track(TRACKING.deleteConfirmCategory, {
            merchantName: user.name,
            categoryName: categories[indexCategorySelect].name,
            status: "failed",
            error: e.response.data,
          });
          updateNotification(
            true,
            failed.associationDeleteCategory.content,
            failed.associationDeleteCategory.variant
          );
        } else {
          _analyticsService.Track(TRACKING.deleteConfirmCategory, {
            merchantName: user.name,
            categoryName: categories[indexCategorySelect].name,
            status: "failed",
            error: e.response.data.errors,
          });
          updateNotification(
            true,
            failed.deleteCategory.content,
            failed.deleteCategory.variant
          );
        }
      });
  };

  const handleNameChange = (value, index) => {
    let errorMessage = "";

    var listErros = listCategoryErrors;

    if (value === "") {
      listErros.push(index);
      setListCategoryErrors(listErros);
      errorMessage = "Preenchimento obrigatório";
    } else {
      setListCategoryErrors(
        listErros.filter((itemIndex) => itemIndex !== index)
      );
      errorMessage = "";
    }

    // atualiza o contexto com as alterações do front-end
    setCategories((current) => {
      return [
        ...current.slice(0, index),
        {
          ...current[index],
          name: value,
          nameError: errorMessage,
          isChange: true,
        },
        ...current.slice(index + 1),
      ];
    });

    return !!errorMessage;
  };

  const handleNewNameChange = (value, index) => {
    let errorMessage = "";

    if (value === "") {
      errorMessage = "Preenchimento obrigatório";
    } else {
      errorMessage = "";
    }

    // atualiza o contexto com as alterações do front-end
    setNewCategory((current) => {
      return [
        ...current.slice(0, index),
        {
          ...current[index],
          name: value,
          nameError: errorMessage,
          isChange: true,
        },
        ...current.slice(index + 1),
      ];
    });

    return !!errorMessage;
  };

  const handleDisplayOrderChange = (value, categoryIndex) => {
    let errorMessage = "";

    var listErros = listCategoryErrors;

    if (value === "") {
      listErros.push(categoryIndex);
      setListCategoryErrors(listErros);
      errorMessage = "Preenchimento obrigatório";
    } else if (value < 1) {
      listErros.push(categoryIndex);
      setListCategoryErrors(listErros);
      errorMessage = "Insira um valor maior ou igual a 1";
    } else {
      setListCategoryErrors(
        listErros.filter((itemIndex) => itemIndex !== categoryIndex)
      );
      errorMessage = "";
    }

    // atualiza o contexto com as alterações do front-end
    setCategories((current) => {
      return [
        ...current.slice(0, categoryIndex),
        {
          ...current[categoryIndex],
          position: value,
          positionError: errorMessage,
          isChange: true,
        },
        ...current.slice(categoryIndex + 1),
      ];
    });

    return !!errorMessage;
  };

  const handleDisplayNewOrderChange = (value, categoryIndex) => {
    let errorMessage = "";

    if (value === "") {
      errorMessage = "Preenchimento obrigatório";
    } else if (value < 1) {
      errorMessage = "Insira um valor maior ou igual a 1";
    } else {
      errorMessage = "";
    }

    // atualiza o contexto com as alterações do front-end
    setNewCategory((current) => {
      return [
        ...current.slice(0, categoryIndex),
        {
          ...current[categoryIndex],
          position: value,
          positionError: errorMessage,
          isChange: true,
        },
        ...current.slice(categoryIndex + 1),
      ];
    });

    return !!errorMessage;
  };

  const handleSaveClick = async () => {
    let hasChanges = false;

    if (listCategoryErrors.length === 0) {
      let failed = [];
      let countUpdateOrAdd = 0;
      let countUpdateOrAddSuccess = 0;
      let messageError = "";

      for (let category of newCategory) {
        if (category.name !== "") {
          hasChanges = true;
          countUpdateOrAdd++;
          await createCategory(user.token, category.name, category.position)
            .then(() => {
              countUpdateOrAddSuccess++;
            })
            .catch((e) => {
              messageError += " | " + e.response.data.errors;
              failed.push(category);
            });
        }
      }

      for (let category of categories) {
        if (category.isChange) {
          hasChanges = true;
          if (category.name === "") {
            messageError += "Não é possivel salvar categoria sem nome | ";
            failed.push(category);
          } else {
            countUpdateOrAdd++;
            await updateCategory(
              user.token,
              category.id,
              category.name,
              category.position
            )
              .then(() => {
                countUpdateOrAddSuccess++;
              })
              .catch((e) => {
                messageError += e.response.data.errors + " | ";
                failed.push(category);
              });
          }
        }
      }

      if (hasChanges) {
        if (failed.length === 0) {
          _analyticsService.Track(TRACKING.saveNewCategory, {
            merchantName: user.name,
            numCategory: countUpdateOrAdd,
            numSuccess: countUpdateOrAddSuccess,
            numError: failed.length,
          });

          updateNotification(
            true,
            successfull.updateCategory.content,
            successfull.updateCategory.variant
          );
          navigate(`/?` + getUrlParams(searchParams.get("appData")));
        } else {
          _analyticsService.Track(TRACKING.saveNewCategory, {
            merchantName: user.name,
            numCategory: countUpdateOrAdd,
            numSuccess: countUpdateOrAdd - failed.length,
            numError: failed.length,
            error: messageError,
          });
          updateNotification(
            true,
            failed.updateCategory.content,
            failed.updateCategory.variant
          );
        }
      } else {
        navigate(`/?` + getUrlParams(searchParams.get("appData")));
      }
    }
  };

  const handleClickCancelar = () => {
    _analyticsService.Track(TRACKING.cancelCategory, {
      merchantName: user.name,
    });

    setNewCategory([]);
    navigate(`/?` + getUrlParams(searchParams.get("appData")));
  };

  const addNewCategory = () => {
    _analyticsService.Track(TRACKING.addNewCategory, {
      merchantName: user.name,
    });

    setTitlePage("Nova Categoria de Produtos");

    setNewCategory((current) => {
      return [
        ...current,
        {
          name: "",
          position: 1,
          nameError: "Preenchimento obrigatório",
          positionError: "",
          isChange: false,
        },
      ];
    });
  };

  return (
    <div className="category-list-container">
      <div className="category-list-wrapper">
        <span className="page-category-header">{titlePage}</span>
        <AlertModal
          open={openDeleteAlert}
          title="Exclusão de Categoria de Produto"
          content="Atenção! Se você excluir essa categoria, não poderá mais recuperá-lo. Você tem certeza disso?"
          handleAgreeEvent={() => removeCategory()}
          handleDisagreeEvent={() => setOpenDeleteAlert(false)}
        />
        <div className="category-list-scroll">
          {newCategory.map((category, index) => {
            return (
              <div className="category-item" key={index}>
                <div className="category-item-title">
                  <TextArea
                    placeholder="Nome da categoria"
                    label="Nome da categoria"
                    text={category?.name}
                    maxLength={128}
                    emitChange={(value) => handleNewNameChange(value, index)}
                    error={!!category?.nameError}
                    helperText={category?.nameError}
                  />
                </div>

                <div className="category-item-position">
                  <TextArea
                    type="number"
                    label="Ordem de exibição"
                    text={category?.position}
                    emitChange={(value) =>
                      handleDisplayNewOrderChange(value, index)
                    }
                    error={!!category?.positionError}
                    helperText={category?.positionError}
                    min={1}
                  />
                </div>
              </div>
            );
          })}

          <div className="header-container">
            <span className="title">Categorias</span>

            <div className="button-container">
              <Button
                variant="outlined"
                startIcon={<AddOutlinedIcon />}
                onClick={() => addNewCategory()}
              >
                Adicionar categoria
              </Button>
            </div>
          </div>

          {categories.map((category, index) => {
            return (
              <div className="category-item" key={index}>
                <div className="category-item-title">
                  <TextArea
                    placeholder="Nome da categoria"
                    label="Nome da categoria"
                    text={category?.name}
                    maxLength={128}
                    emitChange={(value) => handleNameChange(value, index)}
                    error={!!category?.nameError}
                    helperText={category?.nameError}
                  />
                </div>

                <div className="category-item-position">
                  <TextArea
                    type="number"
                    label="Ordem de exibição"
                    text={category?.position}
                    emitChange={(value) =>
                      handleDisplayOrderChange(value, index)
                    }
                    error={!!category?.positionError}
                    helperText={category?.positionError}
                    min={1}
                  />
                </div>
                <IconButton
                  onClick={() => handleDeleteCategory(category.id, index)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            );
          })}
        </div>
      </div>
      <div className="category-buttons-container">
        <div className="pagebuttons-container-right">
          <div className="page-buttons">
            <Button
              variant="outlined"
              startIcon={<CloseOutlinedIcon />}
              onClick={() => handleClickCancelar()}
              style={{ marginRight: 14 }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={listCategoryErrors.length > 0}
              startIcon={<CheckOutlinedIcon />}
              onClick={() => handleSaveClick()}
            >
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
