export const Environment = {
  Development: "development",
  Staging: "staging",
  Production: "production",
};

const CurrentEnvironment =
  process.env.REACT_APP_ENVIRONMENT ?? Environment.Development;

function IsDevelopment() {
  return CurrentEnvironment === Environment.Development;
}

function IsStaging() {
  return CurrentEnvironment === Environment.Staging;
}

function IsProduction() {
  return CurrentEnvironment === Environment.Production;
}

export const Environments = {
  IsDevelopment,
  IsStaging,
  IsProduction,
};
