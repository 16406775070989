import React, { useEffect, useState } from "react";
import { convertToReal, validatePrice } from "../../../services/common";
import "./style.scss";
import { useStore } from "../../../services/ZustandConfig";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TextArea } from "../../TextArea";

export const GroupItem = (props) => {
  const [title, setTitle] = useState();
  const [price, setPrice] = useState();
  const [qtdMaxima, setQtdMaxima] = useState();
  //const [group, setGroup] = useContext(GroupContext);
  let [group, editGroupItemByIndex] = useStore((state) => [
    state.group,
    state.editGroupItemByIndex,
  ]);

  useEffect(() => {
    const errorMessageTitle = props?.title ? "" : "Preenchimento obrigatório";
    setTitle({ value: props?.title, error: errorMessageTitle });
    const price = convertToReal(props?.price).substring(3);
    setPrice({ value: price, error: "" });
    setQtdMaxima({ value: props?.qtdMaxima, error: "" });
  }, []);

  // toda vez que o componente GroupInformation atualizar o campo de maxQuantity, esse componente vai zerar o campo dos max quantidade dos itens
  useEffect(() => {
    setQtdMaxima({ value: props?.qtdMaxima, error: qtdMaxima?.error });
  }, [props]);

  const handleDeleteGroup = () => {
    props.deleteEvent();
  };

  const handleTitleChange = (value) => {
    const errorMessage = value ? "" : "Preenchimento obrigatório";
    setTitle({ value, error: errorMessage });

    const editedGroupItem = {
      ...group.groupItemViewDto[props?.indexValue],
      name: value,
      titleError: errorMessage,
    };

    editGroupItemByIndex(props?.indexValue, editedGroupItem);

    return !!errorMessage;
  };

  const handlePriceChange = (value) => {
    let errorMessage;
    if (value) {
      errorMessage = validatePrice(value) ? "" : "Insira um valor válido.";
    } else {
      errorMessage = "";
    }

    setPrice({ value, error: errorMessage });

    const editedGroupItem = {
      ...group.groupItemViewDto[props?.indexValue],
      price: value,
      priceError: errorMessage,
    };

    editGroupItemByIndex(props?.indexValue, editedGroupItem);

    return !!errorMessage;
  };

  const handleDescriptionChange = (value) => {
    // atualiza o contexto com as alterações do front-end
    const editedGroupItem = {
      ...group.groupItemViewDto[props?.indexValue],
      description: value,
    };

    editGroupItemByIndex(props?.indexValue, editedGroupItem);
  };

  return (
    <div className="group-item">
      <div className="group-item-title">
        <TextArea
          placeholder="Nome da opção"
          label="Título"
          text={title?.value}
          maxLength={255}
          emitChange={(value) => handleTitleChange(value)}
          error={!!title?.error}
          helperText={title?.error}
        />
      </div>

      <div className="group-item-price">
        <TextArea
          placeholder="Preço"
          label="Preço"
          text={price?.value}
          maxLength={128}
          emitChange={(value) => handlePriceChange(value)}
          error={!!price?.error}
          helperText={price?.error}
        />
      </div>

      <div className="group-item-description">
        <TextArea
          placeholder="Descrição"
          label="Descrição"
          text={props?.description}
          maxLength={200}
          emitChange={(value) => handleDescriptionChange(value)}
        />
      </div>

      <div className="group-item-button-delete">
        <IconButton aria-label="delete" onClick={() => handleDeleteGroup()}>
          <DeleteOutlineIcon />
        </IconButton>
      </div>
    </div>
  );
};
