import React, { useEffect } from "react";
import "./style.scss";
import { GroupManager } from "../../../Components/Groups/GroupManager";

export const EditGroup = () => {
  useEffect(() => {}, []);

  return (
    <div className="page-container">
      <span className="page-header">Grupo de Complementos</span>
      <GroupManager disabled={true} />
    </div>
  );
};
